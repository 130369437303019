@import "_normalize.scss";
.main {
    position: relative;
    width: 100%;
    margin-top: 25px;
    @media (max-width: 550px) {
        margin-top: 15px;
    }
}
.home {
    &__container {
        position: relative;
        margin: 0 auto;
        max-width: 1240px;
        padding: 0px 40px;
        @media (max-width: 750px) {
            padding: 0px 20px;
        }
        @media (max-width: 550px) {
            padding: 0px 10px;
        }
    }
}
.homeBg {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 1390px;
    height: 575px;
    border-radius: 40px;
    &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 89%;
        background-repeat: no-repeat;
        border-radius: 40px;
        background-image: url(../assets/HomeBG.png);
        z-index: -3;
    }
}
.wrapper {
    position: relative;
    max-width: 1420px;
    margin: 0 auto;
    padding: 0 15px;
    @media (max-width: 550px) {
        padding: 0 5px;
    }
}
.container {
    position: relative;
    margin: 0 auto;
    max-width: 1240px;
    padding: 0px 40px;
    @media (max-width: 750px) {
        padding: 0px 20px;
    }
    @media (max-width: 550px) {
        padding: 0px 10px;
    }
}
.header {
    padding: 26px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title1 {
    padding-top: 54px;
    color: #fff;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    @media (max-width: 1150px) {
        font-size: 55px;
    }
    @media (max-width: 1050px) {
        font-size: 50px;
    }
    @media (max-width: 950px) {
        font-size: 45px;
    }
    @media (max-width: 850px) {
        font-size: 40px;
    }
    @media (max-width: 750px) {
        font-size: 35px;
        padding-top: 70px;
    }
    @media (max-width: 550px) {
        font-size: 30px;
        padding-top: 70px;
    }
}
.title2 {
    padding-top: 10px;
    color: #fff;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 30px */
    text-transform: uppercase;
    span {
        color: #27a2dc;
    }
    @media (max-width: 1050px) {
        font-size: 28px;
    }
    @media (max-width: 950px) {
        font-size: 26px;
    }
    @media (max-width: 850px) {
        font-size: 25px;
    }
    @media (max-width: 750px) {
        font-size: 20px;
    }
}
.contactContainer {
    display: flex;
    align-items: center;
    @media (max-width: 520px) {
        flex-direction: column;
        gap: 6px;
    }
}

.about {
    position: relative;
    color: #fff;
    text-align: right;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
    padding-right: 32px;
    &::after {
        position: absolute;
        content: "";
        top: 9px;
        left: -23px;
        width: 8px;
        height: 8px;
        background-color: #29a1db;
        border-radius: 100%;
        @media (max-width: 700px) {
            top: 6px;
        }
        @media (max-width: 520px) {
            left: 0px;
        }
    }
    @media (max-width: 700px) {
        font-size: 18px;
        padding-right: 22px;
    }
    @media (max-width: 520px) {
        font-size: 16px;
        padding-left: 18px;
    }
}

.tel {
    color: #fff;
    text-align: right;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
    text-transform: uppercase;
    @media (max-width: 700px) {
        font-size: 18px;
    }
    @media (max-width: 520px) {
        font-size: 16px;
    }
}

.subtitle {
    padding-top: 10px;
    color: #fff;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    @media (max-width: 850px) {
        font-size: 17px;
    }
    @media (max-width: 750px) {
        font-size: 16px;
    }
}

.advantages {
    padding-top: 54px;
    padding-bottom: 110px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    p {
        color: #6acfff;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        text-transform: uppercase;
        @media (max-width: 600px) {
            font-size: 17px;
        }
        @media (max-width: 550px) {
            font-size: 15px;
        }
    }
    @media (max-width: 850px) {
        gap: 18px;
    }
    @media (max-width: 750px) {
        gap: 16px;
    }
    @media (max-width: 650px) {
        gap: 14px;
    }
    @media (max-width: 600px) {
        gap: 12px;
    }
    @media (max-width: 550px) {
        gap: 10px;
    }
}

.conditions {
    padding: 0px 10px;
    margin-top: 200px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    align-items: center;
    &__img {
        max-width: 460px;
        min-width: 300px;
        img {
            width: 100%;
        }
    }
    &__inform {
        display: flex;
        flex-direction: column;
    }
    &__title {
        color: #262626;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 36px */
        text-transform: uppercase;
        @media (max-width: 1050px) {
            font-size: 28px;
        }
        @media (max-width: 950px) {
            font-size: 26px;
        }
        @media (max-width: 850px) {
            font-size: 25px;
        }
        @media (max-width: 750px) {
            font-size: 20px;
        }
    }
    @media (max-width: 1135px) {
        margin-top: 300px;
    }
    @media (max-width: 825px) {
        gap: 20px;
        flex-direction: column;
    }
    @media (max-width: 665px) {
        margin-top: 400px;
    }
}
.pluses {
    padding-top: 20px;
    padding-bottom: 30px;
    display: flex;
    gap: 20px;
    align-items: center;
    div {
        span {
            display: flex;
            align-items: end;
            gap: 5px;
            color: #27a2dc;
            text-align: center;
            font-family: Montserrat;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%; /* 30px */
            text-transform: uppercase;
            padding-bottom: 5px;
            p {
                color: #27a2dc;
            }
            @media (max-width: 1050px) {
                font-size: 25px;
            }
            @media (max-width: 950px) {
                font-size: 22px;
            }
            @media (max-width: 850px) {
                font-size: 20px;
            }
            @media (max-width: 750px) {
                font-size: 17px;
            }
            @media (max-width: 550px) {
                font-size: 14px;
            }
        }
        p {
            color: #262626;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 18px */
            @media (max-width: 850px) {
                font-size: 17px;
            }
            @media (max-width: 750px) {
                font-size: 16px;
            }
            @media (max-width: 550px) {
                font-size: 14px;
            }
        }
    }
    &__line {
        color: #262626;
        text-align: center;
        font-family: Montserrat;
        font-size: 60px;
        font-style: normal;
        font-weight: 275;
        line-height: 100%; /* 60px */
    }
    @media (max-width: 910px) {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.categoryPluses {
    display: flex;
    flex-direction: column;
    gap: 25px;
    li {
        position: relative;
        color: #262626;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 18px */
        padding-left: 25px;
        &::after {
            position: absolute;
            content: "";
            top: 5px;
            left: 0;
            width: 10px;
            height: 10px;
            background-color: #27a2dc;
            border-radius: 100%;
        }
        @media (max-width: 850px) {
            font-size: 17px;
        }
        @media (max-width: 750px) {
            font-size: 16px;
        }
        @media (max-width: 550px) {
            font-size: 14px;
        }
    }
    @media (max-width: 900px) {
        gap: 15px;
    }
}

.wrapperInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1120px) {
        flex-direction: column;
        gap: 80px;
    }
    @media (max-width: 550px) {
        gap: 40px;
    }
}

.privilege {
    position: relative;
    margin-top: 100px;
    background-color: #0c1820;
    padding: 57px 0px;
    width: 100%;
    &__title {
        max-width: 484px;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 36px */
        text-transform: uppercase;
        span {
            color: #27a2dc;
            font-family: Montserrat;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            @media (max-width: 1050px) {
                font-size: 28px;
            }
            @media (max-width: 950px) {
                font-size: 26px;
            }
            @media (max-width: 850px) {
                font-size: 25px;
            }
            @media (max-width: 750px) {
                font-size: 20px;
            }
        }
        @media (max-width: 1050px) {
            font-size: 28px;
        }
        @media (max-width: 950px) {
            font-size: 26px;
        }
        @media (max-width: 850px) {
            font-size: 25px;
        }
        @media (max-width: 750px) {
            font-size: 20px;
        }
    }
    &__subtitle {
        color: #b3b3b3;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }
    &__container {
        display: flex;
        flex-direction: column;
        gap: 35px;
        @media (max-width: 750px) {
            gap: 30px;
        }
        @media (max-width: 650px) {
            gap: 27px;
        }
        @media (max-width: 600px) {
            gap: 22px;
        }
        @media (max-width: 550px) {
            gap: 18px;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        gap: 30px;
        &__subtitle {
            color: #9d9d9d;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 18px */
            @media (max-width: 850px) {
                font-size: 15px;
            }
            @media (max-width: 750px) {
                font-size: 14px;
            }
        }
        @media (max-width: 400px) {
            gap: 10px;
        }
    }
    &__img {
        position: relative;
        width: 100%;
        max-width: 600px;
        min-width: 500px;
        img {
            width: 100%;
        }
        @media (max-width: 550px) {
            min-width: 300px;
        }
    }
    &__text {
        display: flex;
        flex-direction: column;
        gap: 10px;
        p {
            color: #fff;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 21.6px */
            @media (max-width: 850px) {
                font-size: 17px;
            }
            @media (max-width: 750px) {
                font-size: 16px;
            }
            @media (max-width: 550px) {
                font-size: 14px;
            }
        }
    }
    @media (max-width: 825px) {
        gap: 20px;
        flex-direction: column;
    }
}

.HowItWorks {
    margin-top: 100px;
    &__title {
        color: #262626;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 36px */
        text-transform: uppercase;
        span {
            color: #27a2dc;
        }
        @media (max-width: 1050px) {
            font-size: 28px;
        }
        @media (max-width: 950px) {
            font-size: 26px;
        }
        @media (max-width: 850px) {
            font-size: 25px;
        }
        @media (max-width: 750px) {
            font-size: 20px;
        }
    }
    &__container {
        display: flex;
        position: relative;
        max-width: 1160px;
        margin-top: 50px;
        @media (max-width: 1270px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
    &__containerBg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        max-width: 1160px;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        @media (min-width: 1270px) {
            background-image: url(../assets/BgWork.png);
            background-size: cover;

            height: 268px;
        }
        @media (max-width: 1270px) {
            background-image: url(../assets/BgWork3.png);
            height: 812px;
        }
    }
    &__text2 {
        max-width: 280px;
        div {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 44px;
            margin-left: 48px;
            @media (max-width: 1270px) {
                margin-top: 26px;
                margin-left: 30px;
            }
        }
        &__span {
            margin-top: 23px;
            margin-left: 176px;
            color: #fff;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 60px */
            @media (max-width: 1270px) {
                font-size: 50px;
                margin-top: 57px;
                margin-left: 188px;
            }
        }
        &__p {
            color: #fff;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 16px */
            @media (max-width: 1270px) {
                font-size: 14px;
            }
        }
    }
    &__text3 {
        max-width: 268px;
        &__span {
            margin-top: 23px;
            margin-left: 182px;
            color: #fff;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 60px */
            @media (max-width: 1270px) {
                font-size: 50px;
                margin-top: 57px;
                margin-left: 188px;
            }
        }
        &__p {
            margin-top: 44px;
            margin-left: 48px;
            color: #fff;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 16px */
            @media (max-width: 1270px) {
                font-size: 14px;
                margin-top: 30px;
                margin-left: 30px;
            }
        }
    }
    &__text4 {
        max-width: 280px;
        div {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 44px;
            margin-left: 48px;
            @media (max-width: 1270px) {
                margin-top: 30px;
                margin-left: 30px;
            }
        }
        &__span {
            margin-top: 23px;
            margin-left: 190px;
            color: #fff;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 60px */
            @media (max-width: 1270px) {
                font-size: 50px;
                margin-top: 67px;
                margin-left: 188px;
            }
        }
        &__p {
            color: #fff;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 16px */
            @media (max-width: 1270px) {
                font-size: 14px;
            }
        }
    }
    &__subtext {
        color: #fff;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%; /* 14px */
    }
    &__text1 {
        max-width: 277px;
        &__span {
            margin-top: 23px;
            margin-left: 191px;
            color: #29a1db;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 60px */
            @media (max-width: 1270px) {
                font-size: 50px;
                margin-top: 30px;
                margin-left: 198px;
            }
        }
        &__p {
            margin-top: 44px;
            margin-left: 48px;
            color: #262626;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 16px */
            @media (max-width: 1270px) {
                font-size: 14px;
                margin-top: 26px;
                margin-left: 30px;
            }
        }
    }
}

.contactUs {
    margin-top: 100px;
    border-radius: 30px;
    background: #0c1820;
    &__title {
        margin-left: auto;
        margin-right: auto;
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 30px */
    }
    &__subtitle {
        margin-left: auto;
        margin-right: auto;
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 30px */
    }
    &__form {
        padding: 25px 105px 22px 105px;

        &__container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-top: 23px;
        }
        @media (max-width: 950px) {
            padding: 15px 55px 12px 55px;
        }
        @media (max-width: 750px) {
            padding: 15px 30px 12px 30px;
        }
        @media (max-width: 550px) {
            padding: 15px 20px 12px 20px;
        }
    }
    &__name {
        width: 230px;
        height: 50px;
        padding: 0px 20px 0px 20px;
        border-radius: 15px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: #fafafa;
        @media (max-width: 800px) {
            width: 100%;
        }
    }
    &__phone {
        width: 230px;
        height: 50px;
        padding: 0px 20px 0px 20px;
        border-radius: 15px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: #fafafa;
        @media (max-width: 800px) {
            width: 100%;
        }
    }
    &__email {
        width: 230px;
        height: 50px;
        padding: 0px 20px 0px 20px;
        border-radius: 15px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: #fafafa;
        @media (max-width: 800px) {
            width: 100%;
        }
    }
    &__button {
        border: none;
        width: 230px;
        padding: 17px 20px;
        border-radius: 15px;
        background: #27a2dc;
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 16px */
        letter-spacing: -0.4px;
        padding: 17px 20px;
        cursor: pointer;
        &:hover {
            background-color: #53c1f4;
        }
        &:active {
            background-color: #0080bd;
        }
        &:disabled {
            border-radius: 15px;
            background: #a4d1e7;
            cursor: not-allowed;
        }
        @media (max-width: 800px) {
            width: 100%;
        }
    }
    &__required {
        padding-top: 15px;
        padding-bottom: 5px;
        color: #ababab;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 12px */
    }
    &__personal {
        color: #fff;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 12px */
        a {
            text-decoration: underline;
            color: #27a2dc;
        }
    }
    @media (max-width: 1000px) {
        margin-top: 80px;
    }
    @media (max-width: 800px) {
        margin-top: 60px;
    }
    @media (max-width: 600px) {
        margin-top: 40px;
    }
}
.inputContainer {
    position: relative;
}
.inputIncorrect {
    position: absolute;
    font-size: 10px;
    line-height: 8px;
    color: red;
    top: 70%;
    left: 13px;
}
.information {
    margin-top: 100px;
    margin-bottom: 100px;
    &__title {
        display: flex;
        align-items: center;
        gap: 35px;
        h3 {
            color: #262626;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 36px */
            text-transform: uppercase;
            span {
                color: #27a2dc;
            }
            @media (max-width: 1050px) {
                font-size: 28px;
            }
            @media (max-width: 950px) {
                font-size: 26px;
            }
            @media (max-width: 850px) {
                font-size: 25px;
            }
            @media (max-width: 750px) {
                font-size: 20px;
            }
        }
        @media (max-width: 950px) {
            flex-direction: column;
            align-items: start;
            gap: 15px;
        }
    }
    &__subtitle {
        color: #262626;
        font-size: 20px;
        margin-bottom: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 36px */
        @media (max-width: 700px) {
            font-size: 18px;
            margin-bottom: 20px;
        }
        @media (max-width: 520px) {
            font-size: 16px;
            margin-bottom: 10px;
        }
    }
    &__container {
        display: flex;
        margin-top: 30px;
        gap: 55px;
        @media (max-width: 1200px) {
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
        }
    }
    &__item {
        max-width: 350px;
        width: 100%;
        min-height: 320px;
        height: 100%;
        max-height: 350px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -2;
        }
        &__text1 {
            color: #2da3db;
            text-align: center;
            font-family: Montserrat;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 54px */
            text-transform: uppercase;
            span {
                color: #2da3db;
                font-family: Montserrat;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
            }
        }
        &__text2 {
            color: #262626;
            text-align: center;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 16px */
        }
    }
    &__subtext {
        margin-top: 30px;
        color: rgba(38, 38, 38, 0.5);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
    }
    @media (max-width: 1000px) {
        margin-top: 80px;
    }
    @media (max-width: 800px) {
        margin-top: 60px;
    }
    @media (max-width: 600px) {
        margin-top: 40px;
    }
}

.ModalPop {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 350px;
    margin: 0 auto;
    border-radius: 30px;
    background: #fff;
    box-shadow:
        0px 12px 27px 0px rgba(0, 0, 0, 0.04),
        0px 50px 50px 0px rgba(0, 0, 0, 0.03),
        0px 112px 67px 0px rgba(0, 0, 0, 0.02),
        0px 199px 80px 0px rgba(0, 0, 0, 0.01),
        0px 311px 87px 0px rgba(0, 0, 0, 0);
    z-index: 10;
    img {
        margin-bottom: 31px;
    }
    h3 {
        color: #262626;
        text-align: center;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 36px */
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    p {
        color: #262626;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 21.6px */
    }
    &__button {
        position: absolute;
        top: 25px;
        right: 25px;
        width: 24px;
        height: 24px;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 50px 40px;
    }
}
.input__focused {
    width: 230px;
    height: 50px;
    padding: 0px 20px 0px 20px;
    border-radius: 15px;
    border: 1px solid #27a2dc;
    background: #eaf6fc;
}
