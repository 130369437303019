.form {
    position: absolute;
    top: 379px;
    right: 40px;
    margin-left: 40px;
    max-width: 650px;
    border-radius: 30px;
    background: #fff;
    box-shadow:
        0px 12px 27px 0px rgba(0, 0, 0, 0.04),
        0px 50px 50px 0px rgba(0, 0, 0, 0.03),
        0px 112px 67px 0px rgba(0, 0, 0, 0.02),
        0px 199px 80px 0px rgba(0, 0, 0, 0.01),
        0px 311px 87px 0px rgba(0, 0, 0, 0);
    &__container1 {
        display: flex;
        gap: 10px;
        align-items: center;
        padding-top: 15px;
        @media (max-width: 665px) {
            flex-direction: column;
        }
    }
    &__container2 {
        display: flex;
        gap: 10px;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        @media (max-width: 665px) {
            flex-direction: column;
        }
    }
    @media (max-width: 1135px) {
        top: 467px;
    }
    @media (max-width: 765px) {
        max-width: 555px;
    }
    @media (max-width: 555px) {
        right: 10px;
        margin-left: 10px;
    }
}

form {
    padding: 28.5px 40px;
    @media (max-width: 400px) {
        padding: 15px 20px;
    }
}
.title {
    color: #262626;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    @media (max-width: 555px) {
        font-size: 16px;
    }
}
.button {
    border: none;
    width: 280px;
    padding: 17px 20px;
    border-radius: 15px;
    background: #27a2dc;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    letter-spacing: -0.4px;
    padding: 17px 20px;
    cursor: pointer;
    &:hover {
        background-color: #53c1f4;
    }
    &:active {
        background-color: #0080bd;
    }
    &:disabled {
        border-radius: 15px;
        background: rgba(39, 162, 220, 0.2);
        cursor: not-allowed;
    }
    @media (max-width: 665px) {
        width: 100%;
    }
}
.button.disabled {
    border: 1px solid rgba(0, 0, 0, 0.03);
    background: #fafafa;
    color: #cca7a7;
}
.required {
    padding-top: 15px;
    padding-bottom: 5px;
    color: #ababab;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
}
.personal {
    color: #262626;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    a {
        color: #27a2dc;
        text-decoration: underline;
    }
}
.inputContainer {
    position: relative;
}
.inputIncorrect {
    position: absolute;
    font-size: 10px;
    line-height: 8px;
    color: red;
    top: 74%;
    left: 21px;
}
.name {
    display: flex;
    width: 280px;
    height: 50px;
    padding: 0px 20px 0px 20px;
    align-items: center;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #fafafa;
    @media (max-width: 665px) {
        width: 100%;
    }
}
.phone {
    display: flex;
    width: 280px;
    height: 50px;
    padding: 0px 20px 0px 20px;
    align-items: center;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #fafafa;
    @media (max-width: 665px) {
        width: 100%;
    }
}
.email {
    display: flex;
    width: 280px;
    height: 50px;
    padding: 0px 20px 0px 20px;
    align-items: center;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #fafafa;
    @media (max-width: 665px) {
        width: 100%;
    }
}
.input__focused {
    display: flex;
    width: 280px;
    height: 50px;
    padding: 0px 20px 0px 20px;
    align-items: center;
    border-radius: 15px;
    border: 1px solid #27a2dc;
    background: #eaf6fc;
}
