.select {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 0px 50px 0px 20px;
    align-items: center;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #fafafa;
}

.inputSelect {
    position: relative;
    width: 100%;
}
.buttonOpen {
    position: absolute;
    top: 16px;
    right: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.buttonClose {
    position: absolute;
    top: 16px;
    right: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.containerInput {
    position: relative;
    width: 280px;
    @media (max-width: 665px) {
        width: 100%;
    }
}
.modal {
    position: absolute;
    top: 120%;
    left: -90%;
    padding: 20px 26.5px;
    border-radius: 30px;
    background: #fff;
    box-shadow:
        0px 12px 27px 0px rgba(0, 0, 0, 0.04),
        0px 50px 50px 0px rgba(0, 0, 0, 0.03),
        0px 112px 67px 0px rgba(0, 0, 0, 0.02),
        0px 199px 80px 0px rgba(0, 0, 0, 0.01),
        0px 311px 87px 0px rgba(0, 0, 0, 0);
    z-index: 10;
    @media (max-width: 765px) {
        right: 0px;
    }
    @media (max-width: 665px) {
        left: 0px;
    }
}
.modalCategory {
    max-width: 478px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 20px;
    li {
        padding: 10px;
        color: #27a2dc;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 12px */
        border-radius: 10px;
        border: 2px solid #eaf6fc;
        cursor: pointer;
        &:hover {
            background-color: #eaf6fc !important;
        }
    }
}
.apply {
    padding: 12px 15px;
    border-radius: 10px;
    background: #27a2dc;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    letter-spacing: -0.35px;
    margin-right: 20px;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: #53c1f4;
    }
    &:active {
        background-color: #0080bd;
    }
    @media (max-width: 330px) {
        margin-bottom: 10px;
    }
}
.throwOff {
    color: #262626;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    letter-spacing: -0.35px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.showInput {
    display: flex;
    width: 280px;
    height: 50px;
    padding: 0px 20px 0px 20px;
    align-items: center;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #fafafa;
    margin-top: -5px;
    margin-bottom: 15px;
}
